<template>
    <standard-page definition="An attestation record is a proof of the data veracity created by a KYC provider" sub_title="Attestation" v-bind:title="short_name">
        <template v-slot:breadcrumb v-if="currentRouteName === 'view-attestation'">
            <span><router-link :to="{ name: 'identities-home'}">Identifier</router-link></span>
            <span><router-link :to="{ name: 'identities-catalogs'}">Catalogs</router-link></span>
            <span><router-link :to="{ name: 'identities-catalogs-dids'}" v-if="catalogName">CATALOG: {{catalogName | truncate(20, '.....')}}</router-link></span>
            <span v-if="short_name">DID: {{short_name | truncate(20, '.....')}}</span>
        </template>
        <template v-slot:sub-title>
            <p class="mg-b-10">
                <a href="javascript:void(0);" v-clipboard:copy="did" v-clipboard:success="copied">{{did | did}}</a>
            </p>
        </template>
        <template v-slot:content>
            <div class="table-responsive mt-4" v-if="filterd_verifications.length >0">
                <table class="table table-striped mg-b-0">
                    <thead>
                    <tr>
                        <th class="tx-left">#</th>
                        <th class="tx-left">Verified By</th>
                        <th class="tx-left">Verified On</th>
                        <th class="tx-left">Transaction</th>
                        <th class="tx-left">Expiry</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-bind:key="index" v-for="(verification, index) in filterd_verifications">
                        <td class="masked">{{page*per_page +index+1}}</td>
                        <td>{{verification.verified_by}}</td>
                        <td>{{verification.created | timestamp}}</td>
                        <extrinsic-link :extrinsic="verification.extrinsic"></extrinsic-link>
                        <td>{{verification.expiry | timestamp}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-body card-blue" v-else>
                <h4 class="card-title text-muted text-center">No records found</h4>
            </div>
            <pager @pageChange="pageChange"
                   @pagerDefaults="pagerDefaults"
                   @perPageChange="perPageChange"
                   v-bind:pages="pages">
            </pager>
        </template>
    </standard-page>
</template>

<script>
    import ExtrinsicLink from '@/components/ExtrinsicLink';
    import Pager from '@/components/Pager';
    import _ from "lodash";

    export default {
        name: "ViewAttestation",
        components: {Pager, ExtrinsicLink},
        props: ['catalogid', 'did', 'claimid'],
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        },
        data() {
            return {
                catalogName: null,
                short_name: null,
                verifications: [],
                kyc_provider_pub_keys: [],
                filterd_verifications: [],
                pages: 1,
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await Promise.all([
                    this.getCatalog(),
                    this.getDid(),
                    this.getVerificationDetails()
                ]);
            },
            async getCatalog() {
                if(this.catalogid){
                    try {
                        let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}`);
                        this.catalogName = reply.data ? reply.data.name : null;
                    } catch (e) {
                        this.$toastr.e("Error getting catalog name", "Error");
                    }
                }
            },
            async getDid() {
                if(this.catalogid && this.did) {
                    try {
                        let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}/dids/${this.did}`);
                        this.short_name = reply.data ? reply.data.short_name : null;
                    } catch (e) {
                        this.$toastr.e("Error getting DID short_name", "Error");
                    }
                }
            },
            copied() {
                this.$toastr.i("Copied to clipboard", "Copied");
            },
            pageChange(page) {
                this.page = page;
                this.getCatalogs();
            },
            perPageChange(per_page) {
                this.per_page = per_page;
                this.getVerificationDetails();
            },
            pagerDefaults(pageInfo) {
                this.page = pageInfo.page;
                this.per_page = pageInfo.per_page;
                this.getVerificationDetails();
            },
            async getVerificationDetails() {
                if (typeof this.claimid != 'undefined' && this.claimid !== null) {
                    this.verifications = [];
                    try {
                        let params = {
                            page: this.page,
                            per_page: this.per_page
                        };
                        let reply = await this.$identity.get(`/identity/dids/${this.did}/claims/${this.claimid}/attestations`, { params });
                        this.verifications = reply.data ? reply.data.attestations : [];
                        this.kyc_provider_pub_keys = _.map(this.verifications, (verification) => {
                            return verification.verified_by;
                        });
                    } catch (e) {
                        this.$toastr.e("Error retrieving Verification Details", "Error");
                        console.log(e);
                    }
                    await this.getOrgsByPubKey();
                }
            },
            async getOrgsByPubKey() {
                this.filterd_verifications = [];
                try {
                    let reply = await this.$accounts.post(`/accounts/org/by_public_keys`, {
                        pub_keys: this.kyc_provider_pub_keys
                    });
                    let orgs_by_pub_key = reply.data ? reply.data.orgs : [];
                    _.forEach(this.verifications, (verification) => {
                        let filtered_org = _.filter(orgs_by_pub_key, { pub_key: verification.verified_by });
                        verification.verified_by = filtered_org[0].name + " <" + filtered_org[0].email + ">";
                        this.filterd_verifications.push(verification);
                    });
                } catch (e) {
                }
            },
        }
    }
</script>

<style scoped>

</style>